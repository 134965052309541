import React, { FC } from 'react'
import styles from './CommonBanner.module.scss'
import cn from 'classnames'
import LogoIcon from '../../public/icons/banner-title.svg'
import Tags from '../news/Tags'
import { AuthorSmall } from '../common/AuthorSmall/AuthorSmall'
import Corner from './Corner'
import { Author } from '../../interfaces/Author'
import MaxWidth from '../layout/MaxWidth'
import Image from '../common/Image'

const bannerData = {
  title: 'Код нового поколения',
  text: 'Новое всё: стиль, сайт, технологии',
  img: '../../public/images/common-banner.png',
  tags: [
    { id: 'statya', name: 'Статья', slug: 'st' },
    { id: 'exclusiv', name: 'Эксклюзив' },
  ],
  author: {
    name: 'Гайк Даллакян',
    slug: 'aik',
    picture: {
      url: 'https://kod.ru/content/images/2024/09/IMG_4777-1.JPG',
    },
  },
}

const CommonBanner: FC<{ className?: string }> = ({ className }) => {
  return (
    <Corner>
      <Image
        width={1380}
        height={600}
        src={'/images/common-banner.png'}
        alt={bannerData.title}
        className={styles.bg}
      />
      <MaxWidth className={cn(styles.content, className)}>
        <Tags className={styles.tags} tags={bannerData.tags} />
        <a href="/id5" target="_blank" className={styles.info}>
          <h2 className={cn(styles.title, 'h1')}>{bannerData.title}</h2>
          <p className={cn(styles.text, 'h5')}>{bannerData.text}</p>
        </a>
        <AuthorSmall
          author={bannerData.author as Author}
          className={styles.author}
        />
        <LogoIcon className={cn(styles.mainLogo)} />
      </MaxWidth>
    </Corner>
  )
}

export default CommonBanner
