import { FC, createContext } from 'react'
import {
  ReviewsSliderQuery,
  useReviewsSliderQuery,
} from '../queries/generated/main/schema'
import {
  PreviewPostFragment,
  useJobsAndWorksQuery,
} from '../queries/generated/blog/schema'
import { createReviewSlider } from '../utils/createReviewSlider'
import { IReviewCard } from '../interfaces/ReviewCard'

export interface IData {
  nodes: PreviewPostFragment[]
  pageInfo: {
    page?: number
    totalPages: number
    count: number
    totalCount: number
    nextPage?: number
    previousPage?: number
    hasNextPage: boolean
    hasPreviousPage: boolean
  }
}
interface IWorkContext {
  reviewSlider: IReviewCard[]
  workArticlesData: IData
  jobsData: IData
}

export const WorkContext = createContext({} as IWorkContext)

const WorkProvider: FC = ({ children }) => {
  const { data: reviewsData } = useReviewsSliderQuery()
  const reviewSlider = createReviewSlider(reviewsData)

  const {
    data: { workArticlesData, jobsData },
  } = useJobsAndWorksQuery({ context: { clientName: 'blogEndpoint' } })

  return (
    <WorkContext.Provider
      value={{
        reviewSlider,
        workArticlesData,
        jobsData,
      }}
    >
      {children}
    </WorkContext.Provider>
  )
}

export default WorkProvider
