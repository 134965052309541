import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import styles from './Corner.module.scss'

interface CornerProps {
  children: ReactNode
}

const Corner: FC<CornerProps> = ({ children }) => {
  return (
    <div className={cn(styles.container)}>
      {children}
      <span className={cn(styles.corner, styles['left-top'])}></span>
      <span className={cn(styles.corner, styles['left-bottom'])}></span>
      <span className={cn(styles.corner, styles['right-top'])}></span>
      <span className={cn(styles.corner, styles['right-bottom'])}></span>
    </div>
  )
}

export default Corner
